<template>
    <!-- <div :class="[flag? '': 'content-box']"> -->
        <!-- <div :class="[flag? '': 'container']"> -->
            <div class="content operat">
            <h4 class="global_head_title">工作点添加</h4>
            <div class="content_row flex">
                <label class="info" for="">工作点名称<span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="data.name" size="large" placeholder="请输入工作点名称"/>
                </div>
            </div>
              <div class="content_row flex">
                <label class="info" for="">工作点地址</label>
                  <div class="content_right">
                    <a-input v-model="data.address" size="large" placeholder="请输入工作点地址"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">所属部门<span class="redS">*</span></label>
                  <div class="content_right">
                     <a-select show-search option-filter-prop="children" v-model="data.department" class=" ant-select-lg">
                        <a-select-option :value="item.did" v-for="(item,index) in teamList" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                </div>
            </div>
              <div class="content_row flex">
                <label class="info" for="">负责人<span class="redS">*</span></label>
                  <div class="content_right">
                        <a-input size="large"  @focus="focus" :value="onStaff.name||''" @click="staffFlag=true" placeholder="请选择负责人" />
                     <!-- <a-select v-model="data.responsibility" class=" ant-select-lg">
                       <a-select-option :value="item.userid" v-for="(item,index) in staffList" :key="index">{{item.name}}</a-select-option>
                    </a-select> -->
                </div>
            </div>
              <div class="content_row flex">
                <label class="info" for="">组长</label>
                  <div class="content_right">
                     <a-select show-search option-filter-prop="children" v-model="data.groupid" class=" ant-select-lg">
                            <a-select-option :value="item.id" v-for="(item,index) in workerList" :key="index">{{item.wname}}</a-select-option>
                    </a-select>
                </div>
            </div>
             <!-- <div class="content_row flex">
                <label class="info" for="">服务人员数</label>
                  <div class="content_right">
                    <a-input v-model="data.workernum" size="large" type="number" placeholder="请输入服务人员数"/>
                </div>
            </div> -->
             <!-- <div class="content_row flex">
                <label class="info" for="">基数</label>
                  <div class="content_right">
                    <a-input v-model="data.base" size="large" type="number" placeholder="请输入基数"/>
                </div>
            </div> -->
             <div class="content_row flex">
                <label class="info" for="">组长补助</label>
                  <div class="content_right">
                    <a-input v-model="data.group_subsidy" size="large" type="number" placeholder="请输入组长补助"/>
                </div>
            </div>

             <div class="content_row flex">
                <label class="info" for="">餐补</label>
                  <div class="content_right">
                    <a-input v-model="data.meal_subsidy" size="large" type="number" placeholder="请输入餐补"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">交通补助</label>
                  <div class="content_right">
                    <a-input v-model="data.traffic_subsidy" size="large" type="number" placeholder="请输入交通补助"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">其他补助</label>
                  <div class="content_right">
                    <a-input v-model="data.other_subsidy" size="large" type="number" placeholder="请输入其他补助"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">其他扣款</label>
                  <div class="content_right">
                    <a-input v-model="data.other_deduction" size="large" type="number" placeholder="请输入其他扣款"/>
                </div>
            </div>
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
             <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>
            </div>
        <!-- </div> -->
        <!-- </div> -->
</template>
<script>
// import $ from "jquery";
import Staff from "../../../components/staff.vue";
import {isMobile,ajaxUrl,requestXml} from '../../../assets/js/request';
export default {
    components: {
    Staff,
  },
    data() {
        
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            staffList:'', //员工列表
            teamList:'', //部门列表
            workerList:'', //服务人员列表
            staffFlag: false,
            onStaff:{
                'name':'',
                'userid':'',
            },
            data:{
                "name": "",
                "address": "",
                "department": "",
                "responsibility": "",
                "groupid": "",
                "workernum": "",
                "base": "",
                "group_subsidy": "",
                "meal_subsidy": "",
                "traffic_subsidy": "",
                "other_subsidy": "",
                "other_deduction": "",
       
            },
    
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        // this.getStaff();
        this.getTeamList();
        this.getWorker();
         if(this.id){
            this.getDetail();
        }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
        submit(){
            let url='/jieb/Operating/operatadd';
            if(this.id){
                url='/jieb/Operating/operatedit';
                this.data.id=this.id;
            }
            this.data.responsibility=this.onStaff.userid;
            delete this.data.responsibility_name;
             requestXml(url,"POST",(res) => {
                if(res!='error'){
                    if(!this.id){
                        this.$message.success('成功');
                    }
                    let path="/scrm_pc/worker/operatList";
                    if(isMobile()){
                        path = '/scrm_wap/worker/operatList';
                    }
                    this.$router.push({
                        path: path,
                    });
                }
                // if(status!='error'){
                //     this.$message.success('成功');
                // }
                // if(res){
                //     this.$message.info(res.msg);
                // }
            },this.data)
        },
         // 负责人-输入框聚焦是清除其焦点
            focus(e){
            e.srcElement.blur();
            },
        // 分配完
        updateStaffFlag(data){
            console.log(data,"dasds")
            if(data != 1){
                this.onStaff=data;
                this.data.department=data.department_id;
            }
            this.staffFlag = false;
        },
        // 获取部门
        getTeamList() {
            requestXml("/jieb/Target/department", "POST", (res) => {
                this.teamList = res;
            });
        },
        // 获取服务人员
         getWorker(){
            requestXml("/jieb/Worker/myworkers","POST",(res) => {
                this.workerList=res.list;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        // 获取成员
         getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staffList=res;
            })
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Operating/operatedit","GET",(res) => {
                // this.createtime=getDateTime(res.createtime);
                this.data = Object.assign({}, res);
                this.onStaff.name=res.responsibility_name;
                this.onStaff.userid=res.responsibility;
                this.data.department=Number(this.data.department);
                this.data.groupid==0?this.data.groupid='':'';
            },{id: this.id})
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
    
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        .operat  .content_row .info{
        width: 105px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        
    }
</style>
